import styled from "styled-components";

import { COLORS } from "../../../styles/globals/colors";
import {
  MOBILE_460
} from "../../../styles/globals/sizes";

export const Input = styled.input`
  background: #ffffff5f 0% 0% no-repeat padding-box;
  border: 1px solid ${COLORS.white};
  height: 48px;
  color: ${COLORS.white};
  margin-top: 10px;
  margin-bottom: 20px;
  width: 80%;
  padding-left: 23px;
  ::placeholder {
    color: ${COLORS.white} !important;
    font-size: 18px;
  }
  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
  @media (max-width: ${MOBILE_460}px) {
    width: 100%;
  }
`;

export const ErrorMessage = styled.div`
  color: ${COLORS.white};
  margin-top: 10px;
`;
