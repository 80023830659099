import React from "react";
import { CloseButton, ModalContent, ModalWrapper } from "./styles";

const ModalForm = ({ isOpen, onClose, children }) => {
  return (
    <ModalWrapper isOpen={isOpen}>
      <ModalContent>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        {children}
      </ModalContent>
    </ModalWrapper>
  );
};

export default ModalForm;
