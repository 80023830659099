import {
  BottomSection,
  Button,
  ContactContainer,
  Form,
  FormContainer,
  FormMessage,
  Label,
  LabelOption,
  MenuItem,
  RadioContainer,
  RadioInput,
  Select,
  TextContainer,
  TextContent,
  TextContentMobile,
  TextContentSecond,
  Title,
  CheckboxInput,
  CheckboxWrapper,
  CheckboxText,
  CheckboxWrapperLong,
  CheckboxInputLong,
  ModalDescription,
} from "./styles";
import React, { useState } from "react";

import { API_URI } from "../../config";

import { InputForm } from "./input/index";
import axios from "axios";
import ModalForm from "../ModalForm";

const ContactForm = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputs, setInputs] = useState({});
  const [succesForm, setSuccesForm] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [showError, setError] = useState(false);

  const handleChange = (event) => {
    setSuccesForm(false);
    setErrorForm(false);
    setError(false);
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.receiveEmail) {
      try {
        await axios.post(API_URI + "/api/emt/contactMail/", inputs);
        setSuccesForm(true);
        setInputs({});
      } catch (error) {
        setErrorForm(true);
      }
    } else {
      setError(true);
    }
  };

  const handlePrivacyPolicyChange = () => {
    setError(false);
    setInputs((values) => ({
      ...values,
      privacyPolicy: !inputs.privacyPolicy,
    }));
  };

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <ContactContainer id="callBack">
      <TextContainer>
        <Form onSubmit={handleSubmit}>
          <FormContainer>
            <TextContent>
              <Title>Personal information</Title>
              <Label>First Name *</Label>
              <InputForm
                id="firstName"
                type="text"
                name="firstName"
                placeholder="Enter Your First Name"
                errorMsg="Invalid name"
                required={true}
                value={inputs.firstName || ""}
                onChange={handleChange}
              />
              <Label>Last Name *</Label>
              <InputForm
                id="lastName"
                type="text"
                name="lastName"
                placeholder="Enter Your Last Name"
                errorMsg="Invalid email"
                required={true}
                value={inputs.lastName || ""}
                onChange={handleChange}
              />
              <Label>Email Address *</Label>
              <InputForm
                id="email"
                type="email"
                name="email"
                placeholder="Enter Your Email Address"
                errorMsg="Invalid email"
                required={true}
                value={inputs.email || ""}
                onChange={handleChange}
              />
              <Label>Date Of Birth *</Label>
              <InputForm
                id="date"
                type="date"
                name="date"
                placeholder="Enter Your Date Of Birth"
                errorMsg="Invalid date"
                required={true}
                value={inputs.date || ""}
                onChange={handleChange}
              />
              <Title>Job preferences</Title>
              <Label>Preferred county/base?</Label>
              <Select
                labelId="county"
                id="county"
                name="county"
                value={inputs.county}
                label="county"
                onChange={handleChange}
              >
                <MenuItem value={"Livingston"}>Livingston</MenuItem>
                <MenuItem value={"Paterson"}>Paterson</MenuItem>
                <MenuItem value={"Brick"}>Brick</MenuItem>
                <MenuItem value={"Ewing"}>Ewing</MenuItem>
                <MenuItem value={"Camden"}>Camden</MenuItem>
                <MenuItem value={"Somerset"}>Somerset</MenuItem>
              </Select>
              <Label>Preferred shift?</Label>
              <Select
                labelId="shift"
                id="shift"
                name="shift"
                value={inputs.shift}
                label="shift"
                onChange={handleChange}
              >
                <MenuItem value={"Day"}>Day</MenuItem>
                <MenuItem value={"Night"}>Night</MenuItem>
              </Select>
              <Label>Weekends?</Label>
              <RadioContainer onChange={handleChange}>
                <LabelOption>
                  <RadioInput
                    checked={inputs["weekends"] === "Yes"}
                    type="radio"
                    value="Yes"
                    name="weekends"
                  />
                  Yes
                </LabelOption>
                <LabelOption>
                  <RadioInput
                    checked={inputs["weekends"] === "No"}
                    type="radio"
                    value="No"
                    name="weekends"
                  />
                  No
                </LabelOption>
              </RadioContainer>
              <Label>Prefer to drive or tech?</Label>
              <RadioContainer onChange={handleChange}>
                <LabelOption>
                  <RadioInput
                    checked={inputs["driveTech"] === "Drive"}
                    id="driveTech"
                    type="radio"
                    value="Drive"
                    name="driveTech"
                  />
                  Drive
                </LabelOption>
                <LabelOption>
                  <RadioInput
                    checked={inputs["driveTech"] === "Tech"}
                    id="driveTech"
                    type="radio"
                    value="Tech"
                    name="driveTech"
                  />
                  Tech
                </LabelOption>
              </RadioContainer>
              <Label>Interested in our health benefits?</Label>
              <RadioContainer onChange={handleChange}>
                <LabelOption>
                  <RadioInput
                    checked={inputs["healthBenefits"] === "Yes"}
                    type="radio"
                    value="Yes"
                    name="healthBenefits"
                  />
                  Yes
                </LabelOption>
                <LabelOption>
                  <RadioInput
                    checked={inputs["healthBenefits"] === "No"}
                    type="radio"
                    value="No"
                    name="healthBenefits"
                  />
                  No
                </LabelOption>
              </RadioContainer>
            </TextContent>
            <TextContentSecond>
              <Label>Phone *</Label>
              <InputForm
                id="phone"
                type="phone"
                name="phone"
                placeholder="Enter Your Phone"
                errorMsg="Invalid phone"
                required={true}
                value={inputs.phone || ""}
                onChange={handleChange}
              />
              <Label>Address *</Label>
              <InputForm
                id="address"
                type="text"
                name="address"
                placeholder="Enter Your Address"
                errorMsg="Invalid address"
                required={true}
                value={inputs.address || ""}
                onChange={handleChange}
              />
              <InputForm
                id="city"
                type="text"
                name="city"
                placeholder="City"
                errorMsg="Invalid city"
                required={true}
                value={inputs.city || ""}
                onChange={handleChange}
              />
              <InputForm
                id="state"
                type="text"
                name="state"
                placeholder="State/ Province"
                errorMsg="Invalid state"
                required={true}
                value={inputs.state || ""}
                onChange={handleChange}
              />
              <InputForm
                id="postal"
                type="text"
                name="postal"
                placeholder="Postal"
                errorMsg="Invalid Postal"
                required={true}
                value={inputs.postal || ""}
                onChange={handleChange}
              />
              <Title>Working Information</Title>
              <InputForm
                id="emtId"
                type="text"
                name="emtId"
                placeholder="EMT ID number "
                errorMsg="Invalid emt id"
                value={inputs.emtId || ""}
                onChange={handleChange}
              />
              <InputForm
                id="experience"
                type="text"
                name="experience"
                placeholder="Years of experience  "
                errorMsg="Invalid experience"
                value={inputs.experience || ""}
                onChange={handleChange}
              />
              <InputForm
                id="workingAt"
                type="text"
                name="workingAt"
                placeholder="Currently, working at?"
                errorMsg="Invalid working at"
                value={inputs.workingAt || ""}
                onChange={handleChange}
              />
            </TextContentSecond>
            <TextContentMobile>
              <Title>Personal information</Title>
              <Label>First Name *</Label>
              <InputForm
                id="firstName"
                type="text"
                name="firstName"
                placeholder="Enter Your First Name"
                errorMsg="Invalid name"
                required={true}
                value={inputs.firstName || ""}
                onChange={handleChange}
              />
              <Label>Last Name *</Label>
              <InputForm
                id="lastName"
                type="text"
                name="lastName"
                placeholder="Enter Your Last Name"
                errorMsg="Invalid email"
                required={true}
                value={inputs.lastName || ""}
                onChange={handleChange}
              />
              <Label>Email Address *</Label>
              <InputForm
                id="email"
                type="email"
                name="email"
                placeholder="Enter Your Email Address"
                errorMsg="Invalid email"
                required={true}
                value={inputs.email || ""}
                onChange={handleChange}
              />
              <Label>Date Of Birth *</Label>
              <InputForm
                id="date"
                type="date"
                name="date"
                placeholder="Enter Your Date Of Birth"
                errorMsg="Invalid date"
                required={true}
                value={inputs.date || ""}
                onChange={handleChange}
              />
              <Label>Phone *</Label>
              <InputForm
                id="phone"
                type="phone"
                name="phone"
                placeholder="Enter Your Phone"
                errorMsg="Invalid phone"
                required={true}
                value={inputs.phone || ""}
                onChange={handleChange}
              />
              <Label>Address *</Label>
              <InputForm
                id="address"
                type="text"
                name="address"
                placeholder="Enter Your Address"
                errorMsg="Invalid address"
                required={true}
                value={inputs.address || ""}
                onChange={handleChange}
              />
              <InputForm
                id="city"
                type="text"
                name="city"
                placeholder="City"
                errorMsg="Invalid city"
                required={true}
                value={inputs.city || ""}
                onChange={handleChange}
              />
              <InputForm
                id="state"
                type="text"
                name="state"
                placeholder="State/ Province"
                errorMsg="Invalid state"
                required={true}
                value={inputs.state || ""}
                onChange={handleChange}
              />
              <InputForm
                id="postal"
                type="text"
                name="postal"
                placeholder="Postal"
                errorMsg="Invalid Postal"
                required={true}
                value={inputs.postal || ""}
                onChange={handleChange}
              />
              <Title>Working Information</Title>
              <InputForm
                id="emtId"
                type="text"
                name="emtId"
                placeholder="EMT ID number "
                errorMsg="Invalid emt id"
                value={inputs.emtId || ""}
                onChange={handleChange}
              />
              <InputForm
                id="experience"
                type="text"
                name="experience"
                placeholder="Years of experience  "
                errorMsg="Invalid experience"
                value={inputs.experience || ""}
                onChange={handleChange}
              />
              <InputForm
                id="workingAt"
                type="text"
                name="workingAt"
                placeholder="Currently, working at?"
                errorMsg="Invalid working at"
                value={inputs.workingAt || ""}
                onChange={handleChange}
              />
              <Title>Job preferences</Title>
              <Label>Preferred county/base?</Label>
              <Select
                labelId="county"
                id="county"
                name="county"
                value={inputs.county}
                label="county"
                onChange={handleChange}
              >
                <MenuItem value={"Livingston"}>Livingston</MenuItem>
                <MenuItem value={"Paterson"}>Paterson</MenuItem>
                <MenuItem value={"Brick"}>Brick</MenuItem>
                <MenuItem value={"Ewing"}>Ewing</MenuItem>
                <MenuItem value={"Camden"}>Camden</MenuItem>
                <MenuItem value={"Somerset"}>Somerset</MenuItem>
              </Select>
              <Label>Preferred shift?</Label>
              <Select
                labelId="shift"
                id="shift"
                name="shift"
                value={inputs.shift}
                label="shift"
                onChange={handleChange}
              >
                <MenuItem value={"Day"}>Day</MenuItem>
                <MenuItem value={"Night"}>Night</MenuItem>
              </Select>
              <Label>Weekends?</Label>
              <RadioContainer onChange={handleChange}>
                <LabelOption>
                  <RadioInput type="radio" value="Yes" name="weekends" />
                  Yes
                </LabelOption>
                <LabelOption>
                  <RadioInput type="radio" value="No" name="weekends" />
                  No
                </LabelOption>
              </RadioContainer>
              <Label>Prefer to drive or tech?</Label>
              <RadioContainer onChange={handleChange}>
                <LabelOption>
                  <RadioInput type="radio" value="Drive" name="driveTech" />
                  Drive
                </LabelOption>
                <LabelOption>
                  <RadioInput type="radio" value="Tech" name="driveTech" />
                  Tech
                </LabelOption>
              </RadioContainer>
              <Label>Interested in our health benefits?</Label>
              <RadioContainer onChange={handleChange}>
                <LabelOption>
                  <RadioInput type="radio" value="Yes" name="healthBenefits" />
                  Yes
                </LabelOption>
                <LabelOption>
                  <RadioInput type="radio" value="No" name="healthBenefits" />
                  No
                </LabelOption>
              </RadioContainer>
            </TextContentMobile>
          </FormContainer>
          <CheckboxWrapper>
            <CheckboxInput
              type="checkbox"
              name="receiveEmail"
              checked={inputs.receiveEmail}
              onChange={handleChange}
            />
            <CheckboxText>
              I consent to receive information from Life Ride
            </CheckboxText>
          </CheckboxWrapper>
          <CheckboxWrapperLong>
            <CheckboxInputLong
              type="checkbox"
              onChange={handlePrivacyPolicyChange}
            />
            <CheckboxText>
              I have read and agree to Life Ride{" "}
              <span
                onClick={handleModalToggle}
                style={{
                  color: "white",
                  zIndex: 150,
                  cursor: "pointer",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                SMS privacy policy
              </span>
            </CheckboxText>
          </CheckboxWrapperLong>
          {showError && (
            <FormMessage>
              Please consent to receive information before sending request
            </FormMessage>
          )}
          <BottomSection>
            <Button type="submit"> Submit application </Button>
            {succesForm ? (
              <FormMessage>Information sent successfully</FormMessage>
            ) : (
              errorForm && (
                <FormMessage>Error. Please try again later</FormMessage>
              )
            )}
          </BottomSection>
        </Form>
      </TextContainer>
      <ModalForm isOpen={isModalOpen} onClose={handleModalToggle}>
        <ModalDescription>
          <span style={{ fontWeight: "bold" }}>Privacy Notice: </span>
          When you opt-in to receive SMS messages from us, we want to assure you
          that we respect your privacy. We do not share or sell your phone
          number or any personal information associated with your opt-in for the
          purposes of SMS or marketing purposes. Your trust is important to us,
          and we use your information solely for the purpose of sending you
          relevant SMS messages as requested. If you have any questions or
          concerns, feel free to contact us at{" "}
          <span style={{ color: "#0000ff", textDecoration: "underline" }}>
            info@liferide.com
          </span>
        </ModalDescription>
      </ModalForm>
    </ContactContainer>
  );
};

export default ContactForm;
