import styled from "styled-components";

import {
  DESKTOP_1200,
  TABLET_800,
  MOBILE_460,
  DESKTOP_1024,
} from "../../styles/globals/sizes";

import { COLORS } from "../../styles/globals/colors";


export const LocationsContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1366px;
  margin: auto;
`;

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Title = styled.h2`
  color: ${COLORS.redTitle};
  margin-bottom: 15px;
  font: 400 40px/50px Roboto;
  text-align: left;
  margin-left: 18px;
  @media (max-width: ${DESKTOP_1200}px) {
    font: 400 35px/40px Roboto;
  }
  @media (max-width: ${DESKTOP_1024}px) {
    font: 400 32px/35px Roboto;
  }
  @media (max-width: ${TABLET_800}px) {
    font: 400 37px/49px Roboto;
  }
  @media (max-width: ${MOBILE_460}px) {
    font: 400 30px/39px Roboto;
  }
`;

export const Line = styled.div`
  width: 88px;
  height: 4px;
  margin-left: 20px;
  background: ${COLORS.redTitle} 0% 0% no-repeat padding-box;
  opacity: 1;
`;

export const LocationItem = styled.div`
  width: 395px;
  height: 331px;
  margin-right: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  @media (max-width: ${MOBILE_460}px) {
    margin-right: 0;
    align-items: center;
  }
`;

export const LocationImage = styled.img`
  width: 400px;
  height: 235px;
  margin-bottom: 10px;
  @media (max-width: ${MOBILE_460}px) {
    width: 374px;
    height: 212px;
  }
`;

export const LocationName = styled.text`
  text-align: left;
  font: 500 20px/32px Roboto;
  color: ${COLORS.gray};
`;

export const LocationText = styled.text`
  text-align: left;
  font: 400 16px/32px Roboto;
  color: ${COLORS.gray};
`;

export const RedCircle = styled.div`
  padding: 3px;
  position: absolute;
  margin-top: 258px;
  display: inline-block;
  background-color: ${COLORS.redTitle};
  border-radius: 50%;
  width: 3px;
  height: 3px;
  @media (max-width: ${MOBILE_460}px) {
    margin-top: 236px;
    margin-right: 370px;
  }
`;

export const LocationPoint = styled.img`
  width: 13px;
  height: 17px;
  margin: 7px 6px 0 0;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  @media (max-width: ${MOBILE_460}px) {
    align-items: baseline;
    width: 95%;
  }
`;
