import styled from "styled-components";

import { COLORS } from "../../styles/globals/colors";
import { TABLET_992, TABLET_800, MOBILE_460 } from "../../styles/globals/sizes";

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1500px;
  width:100%;
  margin: auto;
  
  @media (max-width: ${TABLET_800}px) {
    flex-direction: column;
  }
`;

export const TextContainer = styled.div`
  width: 50%;
  background: transparent
    linear-gradient(270deg, ${COLORS.magenta} 0%, ${COLORS.redTitle} 100%) 0% 0%
    no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 0;

  @media (max-width: ${TABLET_800}px) {
    width: 100%;
  }
  @media (max-width: ${MOBILE_460}px) {
    padding: 30px 0 50px;
  }
`;

export const TextContent = styled.div`
  width: 80%;

  @media (max-width: ${TABLET_800}px) {
    width: 90%;
  }
`;

export const ImageContainer = styled.div`
  width: 50%;
  max-height: 401px;
  @media (max-width: ${TABLET_992}px) {
    max-height: 100%;
  }
  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    height: 248px;
  }
`;

export const Title = styled.h2`
  font: normal normal normal 37px/49px Roboto;
  color: ${COLORS.white};

  @media (max-width: ${MOBILE_460}px) {
    font: normal normal normal 28px/40px Roboto;
  }
`;

export const Line = styled.div`
  width: 20%;
  border-bottom: 4px solid ${COLORS.white};
  margin: 20px 0;
`;

export const Text = styled.div`
  font: 400 20px/28px Roboto;
  color: ${COLORS.white};
  margin-bottom: 27px;

  @media (max-width: ${TABLET_800}px) {
    margin-bottom: 0;
  }
`;

export const OfficeImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
