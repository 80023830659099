import React, { useState } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";

import "./defaultStyles.css";

// import required modules
import { Pagination, Autoplay } from "swiper";

import {
  CredentialsBackground,
  CredentialsContainer,
  CredentialInfo,
  Line,
  BenefitsInfo,
  Title,
  InfoList,
  Item,
  ItemRight,
  ArrowLeft,
  ArrowRight,
  Picture,
  AmbulancePicture,
  CarrouselContent,
  ImageContainer,
  SwiperContainer,
} from "./styles";

import arrow from "../../images/arrow.svg";
import people from "../../images/people.png";
import ambulance from "../../images/ambulance.png";

const Credential = () => {
  const SliderControls = (props) => {
    const swiper = useSwiper();
    const handleNext = () => {
      swiper.slideNext();
    };
    const handlePrev = () => {
      swiper.slidePrev();
    };

    if (props.side === "right")
      return (
        <ArrowRight src={arrow} type={"next"} onClick={() => handleNext()} />
      );
    if (props.side === "left")
      return (
        <ArrowLeft src={arrow} type={"prev"} onClick={() => handlePrev()} />
      );
  };

  return (
    <CredentialsBackground>
      <Swiper
        loop={true}
        autoplay={false}
        //navigation={true}
        speed={700}
        slidesPerView={1}
        spaceBetween={30}
        breakpoints={{
          210: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          480: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
        }}
        // pagination={{
        //   clickable: true,
        //   dynamicBullets: true,
        //   dynamicMainBullets: 2,
        // }}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
      >
        <SliderControls side="left" />
        <SliderControls side="right" />
        <SwiperSlide>
          <CredentialsContainer>
            <CredentialInfo>
              <Title>BENEFITS</Title>
              <Line />
              <CarrouselContent></CarrouselContent>
              <InfoList>
                <Item>Competitive Pay</Item>
                <Item>
                  Flexible schedules are available: Part-time, Full-Time, or Per
                  Diem
                </Item>
                <Item>Shifts available 24/7</Item>
                <Item>Paycheck Advances</Item>
              </InfoList>
            </CredentialInfo>
            <ImageContainer>
              <Picture src={people} alt="emt staff" />
            </ImageContainer>
            <BenefitsInfo>
              <InfoList>
                <ItemRight>No cap on overtime</ItemRight>
                <ItemRight>Health benefits</ItemRight>
                <ItemRight>PTO</ItemRight>
                <ItemRight>Friendly and respectful environment</ItemRight>
              </InfoList>
            </BenefitsInfo>
          </CredentialsContainer>
        </SwiperSlide>
        <SwiperSlide>
          <CredentialsContainer>
            <CredentialInfo>
              <Title>EQUIPMENT</Title>
              <Line />
              <InfoList>
                <Item>New and like-new ambulances.</Item>
                <Item>Power stretchers.</Item>
                <Item>Uniforms.</Item>
              </InfoList>
            </CredentialInfo>
            <AmbulancePicture src={ambulance} alt="life ride ambulance" />
          </CredentialsContainer>
        </SwiperSlide>
      </Swiper>
    </CredentialsBackground>
  );
};

export default Credential;
