import styled from "styled-components";
import VideoPreview from "../../images/base.png";
import Hero from "../../images/hero.jpeg";
import { COLORS } from "../globals/colors";
import {
  DESKTOP_1920,
  DESKTOP_1440,
  DESKTOP_1366,
  DESKTOP_1200,
  DESKTOP_1024,
  TABLET_992,
  TABLET_800,
  TABLET_768,
  TABLET_740,
  MOBILE_575,
  MOBILE_460,
  MOBILE_380,
  MOBILE_320,
} from "../globals/sizes";

export const HeroWrapper = styled.div`
  margin: auto;
  width: 100%;
  max-width: 1500px;
  margin-top: 90px;
`;

export const HeroContainer = styled.div`
  width: 100%;
  background-image: linear-gradient(to left, rgba(230, 230, 230, 1), rgba(230, 230, 230, 0)), url(${Hero});
  height: 768px;
  background-size: cover;
  transform: scaleX(-1);
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: ${TABLET_992}px) {
    display: none;
  }
`;


export const HeroTextContainer = styled.div`
  padding-left: 60px;
  transform: ${(props) =>  props.hasRotate && "scaleX(-1)"};
  @media (max-width: ${TABLET_992}px) {
    padding: 30px 0 60px;
    padding-left: 0px;
    width: 90%;
    margin: auto 0;
    align-self: center;
  }
`;

export const HeroTitle = styled.h1`
  font: normal normal normal 57px/60px Roboto;
  color: #464748;

  @media (max-width: ${TABLET_992}px) {
    font: normal normal 42px/52px Roboto;
    color: #E0281A;
    font-weight: 400;
  }
`;

export const HeroSubTitle = styled.h2`
  font: normal normal 26px/30px Roboto;
  color: #464748;
  max-width: 353px;
  margin-top: 10px;

  margin-bottom: 45px;
  @media (max-width: ${TABLET_992}px) {
    font: normal normal 20px/24px Roboto;
  }
`;

export const HeroButton = styled.a`
  justify-content: center;
  cursor: pointer;
  text-align: center;
  padding: 14px 60px;
  border-radius: 25px;
  font: bold 16px/21px Roboto;
  color: ${COLORS.white};
  background-color: ${COLORS.vermilion};
  border: 0;
  word-wrap: break-word;
  &:hover {
    filter: grayscale(50%);
  }
`;

export const ContactUs = styled.a`
  padding: 14px 60px;
  border-radius: 25px;
  font: bold 16px/21px Roboto;
  color: ${COLORS.white};
  background-color: ${COLORS.vermilion};
  border: 0;
  word-wrap: break-word;
  &:hover {
    filter: grayscale(50%);
  }
`;

export const HeroButtonRef = styled.a`
`;

export const HeroContainerMobile = styled.div`
  display: none;
  @media (max-width: ${TABLET_992}px) {
    display: flex;
    flex-direction: column;
  }
`;

export const HeroImage = styled.img`
  width: 100%;
  height: 500px;
  object-fit: cover;
  transform: scaleX(-1);
  @media (max-width: ${MOBILE_575}px) {
    height: 331px;
  }
`;

export const HeroPicture = styled.picture`

  @media (max-width: ${TABLET_768}px) {
    display: none;
  }
  @media (max-width: ${TABLET_740}px) {
    display: none;
  }
  & > img, 
  & > source {
    width: 100%;
    margin-right: 0%;
    // height: 100%;
    object-fit: cover;
  }
`

export const ContactUsPhone = styled.a`
  width: fit-content;
  padding: 14px 45px;
  border-radius: 25px;
  font: 400 18px/21px Roboto;
  color: ${COLORS.white};
  background-color: ${COLORS.vermilion};
  border: 0;
  word-wrap: break-word;
  display: ${(props) =>  props.mobile ? "none" : "flex"};
  &:hover {
    filter: grayscale(50%);
  }
  @media (max-width: ${DESKTOP_1024}px) {
    font-size: 16px;
  }
  @media (max-width: ${TABLET_768}px) {
    align-self: center;
  }
  @media (max-width: ${MOBILE_460}px) {
    display: ${(props) =>  props.mobile ? "flex" : "none"};
  }
`;

export const MobileDiv = styled.div`
  display: none;
  width: 100%;
  background: ${(props) =>
    props.bgImage
      ? `url(${props.bgImage}) 10% 45%/cover no-repeat`
      : `background: purple;`};
  @media (max-width: ${TABLET_768}px) {
    height: 400px;
    display: block;
    background-position: 80%;
  }
  @media (max-width: 600px) {
    height: 350px;
    background-position: 80%;
  }
  @media (max-width: ${MOBILE_460}px) {
    background-position: 80% 45%;
  }
`;

export const InternalWrapper = styled.div`
  margin: 0;
  position: absolute;
  width: 100%;
  max-width: 1500px;

  @media (max-width: ${DESKTOP_1200}px) {
    max-width: 90%;
  }
  @media (max-width: ${TABLET_992}px) {
    max-width: 90%;
    margin: 0;
    flex: 1;
  }
  @media (max-width: ${TABLET_768}px) {
    max-width: 100%;
    flex: 1;
    position: relative;
  }
  @media (max-width: ${MOBILE_460}px) {
    max-width: 100%;
  }

`;


export const HeroTextDiv = styled.div`
  padding: 50px 30px;
  box-sizing: border-box;
  margin: auto;
  
  @media (max-width: 1600px) {
    width: 90%;
  }
    
  @media (max-width: ${TABLET_992}px) {
    padding: 50px 40px;
    box-sizing: border-box;
  }
  @media (max-width: ${MOBILE_460}px) {
    margin: 30px 20px;
    padding: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
  @media (max-width: ${MOBILE_320}px) {
    margin: 20px;
  }
`;

export const Title = styled.h2`
  width: 6em;
  margin: 40px 0 20px;
  color: ${COLORS.gray};
  text-align: left;
  font: 500  calc(2.75vw)/ calc(3.25vw) "Roboto";
  text-transform: uppercase;

  & > .nowrap {
    white-space: nowrap;
  }

  @media (min-width: ${DESKTOP_1920}px) {
    font-size: 2.8rem;
    line-height: 3.2rem;
  }
  /* @media (max-width: ${DESKTOP_1200}px) {
    width: 30%;
    font: 500 calc(3vw)/ calc(3.5vw) "Roboto";
  } */
  /* @media (max-width: ${TABLET_992}px) {
    width: 30%;
  } */
  @media (max-width: ${TABLET_768}px) {
    width: 100%;
    margin: 14px 0;
    font: 500 31px/36px "Roboto";
    text-align: center;
    align-items: center;
  }
  @media (max-width: ${MOBILE_460}px) {
    // margin: 0 0 15px;
    font: 500 calc(6.5vw)/ calc(9vw) "Roboto";
  }
`;

export const Paragraph = styled.p`
  margin: 20px 0 20px;
  color: ${COLORS.grayText};
  font: 400 26px "Roboto";
  width: 35%;
  @media (min-width: ${DESKTOP_1920}px) {
    width: 31%;
    // font: 400 calc(2.2vw)/ calc(2.4vw) "Roboto";
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
  @media (max-width: ${TABLET_992}px) {
    width: 40%;
    // font: 400 calc(2.4vw)/ calc(2.6vw) "Roboto";
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
  @media (max-width: ${TABLET_768}px) {
    width: 100%;
    margin: 0 0 20px 0;
    // font: 400 22px/26px "Roboto";
    text-align: center;
    align-items: center;
  }
`;

export const PhoneAndParagraph = styled.div`
  @media (max-width: ${TABLET_768}px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const TwoDivsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  width: 100%;
  max-width: 1500px;
  @media (max-width: ${TABLET_800}px) {
    flex-direction: column;
  }
`;

export const ImageContainer = styled.div`
  height: 500px;
  width: 50%;
  @media (max-width: ${DESKTOP_1440}px) {
    height: 470px;
  }
  @media (max-width: ${DESKTOP_1200}px) {
    height: 350px;
  }
  @media (max-width: ${DESKTOP_1024}px) {
    height: 330px;
  }
  @media (max-width: ${TABLET_992}px) {
    height: 400px;
  }
  @media (max-width: 900px) {
    height: 390px;
  }
  @media (max-width: 850px) {
    height: 420px;
  }
  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    height: 488px;
  }
  @media (max-width: ${TABLET_740}px) {
    height: 400px;
  }
  @media (max-width: 640px) {
    height: 350px;
  }
  @media (max-width: 550px) {
    height: 300px;
  }
  @media (max-width: ${MOBILE_460}px) {
    height: 250px;
  }
  @media (max-width: ${MOBILE_380}px) {
    height: 200px;
  }
  `;

export const ImageContent = styled.picture`
  & > img, 
  & > source {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media (max-width: ${DESKTOP_1200}px) {
      object-position: 20%;
    }
    @media (max-width: ${DESKTOP_1024}px) {
      object-position: 40%;
    }
    @media (max-width: ${TABLET_992}px) {
      object-position: 15%;
    }
    @media (max-width: 900px) {
      object-position: 25%;
    }
    @media (max-width: 850px) {
      object-position: 0%;
    }
  }
`

export const TextContainerOurTeam = styled.div`
  width: 100%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0;
  margin: auto;

  @media (max-width: ${DESKTOP_1366}px) {
    padding: 50px 0;
  }
  @media (max-width: ${DESKTOP_1200}px) {
    padding: 20px 0;
  }
  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    height: fit-content;
    padding: 50px 0;
  }
  @media (max-width: ${MOBILE_460}px) {
    padding: 50px 0;
  }
`;

export const StatisticsContainer = styled.div`
width: 100%;
background: #FFFFFF 0% 0% no-repeat padding-box;
display: flex;
flex-direction: row;
align-items: center;
padding: 70px 0;
margin: auto;

@media (max-width: ${DESKTOP_1366}px) {
  padding: 50px 0;
}
@media (max-width: ${DESKTOP_1200}px) {
  padding: 20px 0;
}
@media (max-width: ${TABLET_800}px) {
  width: 100%;
  height: fit-content;
  flex-direction: column;
  padding: 60px 0;
}
@media (max-width: ${MOBILE_460}px) {
  padding: 50px 0;
}
`;

export const StatisticItem = styled.div`
max-width: 1500px;
width: 100%;

display: flex;
flex-direction: column;
align-items: center;
margin: auto;

@media (max-width: ${DESKTOP_1366}px) {
  padding: 50px 0;
}
@media (max-width: ${DESKTOP_1200}px) {
  padding: 20px 0;
}
@media (max-width: ${TABLET_800}px) {
  width: 100%;
  height: fit-content;
  padding: 0;
  margin-top: ${(props) =>  props.hasMarginTop ? "60px" : "0"};
}
`;

export const VideoContainer = styled.div`
  width: 100%;
  height: 591px;
  max-width: 1500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  .react-player__preview{
    background-image: url(${VideoPreview}) !important;
  }
  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    height: 247px;
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  background: transparent linear-gradient(259deg, #ED67A6 0%, #EC4746 100%) 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 0;

  @media (max-width: ${DESKTOP_1366}px) {
    padding: 50px 0;
  }
  @media (max-width: ${DESKTOP_1200}px) {
    padding: 20px 0;
  }
  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    height: fit-content;
    padding: 70px 0;
  }
  @media (max-width: ${MOBILE_460}px) {
    padding: 50px 0;
  }
`;

export const TextContent = styled.div`
  width: 80%;
  margin: auto 0;
  text-align: -webkit-center;

  @media (max-width: ${TABLET_800}px) {
    width: 90%;
  }
`;

export const CardTitle = styled.h2`
  color: ${COLORS.white};
  margin-bottom: 15px;
  font: bold 40px/50px Roboto;
  text-align: center;
  @media (max-width: ${DESKTOP_1200}px) {
    font: 400 35px/40px Roboto;
  }
  @media (max-width: ${DESKTOP_1024}px) {
    margin: 0 0 10px;
    font: 400 32px/35px Roboto;
  }
  @media (max-width: ${TABLET_800}px) {
    font: 400 37px/49px Roboto;
    text-align: left;
  }
  @media (max-width: ${MOBILE_460}px) {
    font: bold 25px/29px Roboto;
    width: 360px;
  }
`;

export const OurTeamTitle = styled.h2`
  color: ${COLORS.redTitle};
  //margin-bottom: 15px;
  font: 500 37px/43px Roboto;
  text-align: center;
  width: 100%;
  @media (max-width: ${DESKTOP_1200}px) {
    font: 500 35px/40px Roboto;
  }
  @media (max-width: ${DESKTOP_1024}px) {
    margin: 0 0 10px;
    font: 500 32px/35px Roboto;
  }
  @media (max-width: ${TABLET_800}px) {
    font: 500 37px/49px Roboto;
    text-align: left;
    width: 90%;
  }
  @media (max-width: ${MOBILE_460}px) {
    font: 500 30px/39px Roboto;
  }
`;

export const StatisticNumber = styled.h2`
  color: ${COLORS.redTitle};
  margin-bottom: 15px;
  font: bold 87px/102px Roboto;
  text-align: center;
  @media (max-width: ${DESKTOP_1024}px) {
    margin: 0 0 10px;
  }
`;

export const StatisticName = styled.text`
  color: ${COLORS.gray};
  margin-bottom: 15px;
  font: 500 32px/38px Roboto;
  text-align: center;
  @media (max-width: ${DESKTOP_1200}px) {
    font: 400 35px/40px Roboto;
  }
  @media (max-width: ${DESKTOP_1024}px) {
    margin: 0 0 10px;
    font: 400 32px/35px Roboto;
  }
  @media (max-width: ${TABLET_800}px) {
    font: 400 37px/49px Roboto;
  }
  @media (max-width: ${MOBILE_460}px) {
    font: 400 30px/39px Roboto;
  }
`;

export const LineBreak = styled.br`
  @media (max-width: ${MOBILE_380}px) {
    display: none;
  }
`;

export const WhiteLine = styled.hr`
  width: 20%;
  min-width: 120px;
  height: 5px;
  float: left;
  background-color: ${COLORS.white};
  color: ${COLORS.white};
  @media (max-width: ${DESKTOP_1200}px) {
    height: 3px;
  }
  @media (max-width: ${MOBILE_460}px) {
    width: 150px;
  }
`;

export const CardParagraph = styled.h3`
  color: ${COLORS.white};
  margin-top: 45px;
  max-width: 1030px;
  font: 400 20px/28px Roboto;
  text-align: center;

  @media (max-width: ${DESKTOP_1200}px) {
    margin-top: 35px;
    font: 400 17px/25px Roboto;
  }
  @media (max-width: ${TABLET_800}px) {
    margin-top: 35px;
    font: 400 18px/26px Roboto;
    text-align: left;
  }
`;

export const PlayButton = styled.img`
  width: 100px;
  height: 100px;
  @media (max-width: ${TABLET_800}px) {
    width: 86px;
    height: 86px;
  }
`;

export const ExternalLink = styled.a`
  text-decoration: none;
  color: inherit;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;
