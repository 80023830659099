import React from "react";

import {
  LocationsContainer,
  CardsContainer,
  TextsContainer,
  LocationItem,
  LocationImage,
  LocationText,
  LocationPoint,
  Line,
  Title,
  RedCircle,
  LocationName,
  TextContainer,
} from "./styles";
import map1 from "../../images/map1.png";
import map2 from "../../images/map2.png";
import map3 from "../../images/map3.png";
import map4 from "../../images/map4.png";
import map5 from "../../images/map5.png";
import map6 from "../../images/map6.png";
import marker from "../../images/icon-map-marker.svg";

const LifeHomeCareLocation = () => {
  return (
    <LocationsContainer>
      <Title>HUBS</Title>
      <Line />
      <CardsContainer>
        <LocationItem>
          <LocationImage src={map1} alt="location on the map" />
          <RedCircle />
          <TextsContainer>
            <LocationName>Life Ride Livingston</LocationName>
            <TextContainer>
              <LocationPoint src={marker} alt="marker" />
              <LocationText>
                70 South Orange Ave., Livingston, NJ 07039
              </LocationText>
            </TextContainer>
          </TextsContainer>
        </LocationItem>
        <LocationItem>
          <LocationImage src={map2} alt="location on the map 2" />
          <RedCircle />
          <TextsContainer>
            <LocationName>Life Ride Paterson </LocationName>
            <TextContainer>
              <LocationPoint src={marker} alt="marker" />
              <LocationText>680 Broadway, Paterson NJ</LocationText>
            </TextContainer>
          </TextsContainer>
        </LocationItem>
        <LocationItem>
          <LocationImage src={map3} alt="location on the map 3" />
          <RedCircle />
          <TextsContainer>
            <LocationName>Life Ride Brick </LocationName>
            <TextContainer>
              <LocationPoint src={marker} alt="marker" />
              <LocationText>
                74 Brick Blvd, Brick Township NJ 08723 Building 4, Suite 120
              </LocationText>
            </TextContainer>
          </TextsContainer>
        </LocationItem>
        <LocationItem>
          <LocationImage src={map4} alt="location on the map 4" />
          <RedCircle />
          <TextsContainer>
            <LocationName>Life Ride Ewing</LocationName>
            <TextContainer>
              <LocationPoint src={marker} alt="marker" />
              <LocationText>Scotch Rd, Ewing, NJ 08628</LocationText>
            </TextContainer>
          </TextsContainer>
        </LocationItem>
        <LocationItem>
          <LocationImage src={map5} alt="location on the map 5" />
          <RedCircle />
          <TextsContainer>
            <LocationName>Life Ride Mount Laurel</LocationName>
            <TextContainer>
              <LocationPoint src={marker} alt="marker" />
              <LocationText>
                1317 Route 73, Unit 105, Mount Laurel, NJ 08054
              </LocationText>
            </TextContainer>
          </TextsContainer>
        </LocationItem>
        <LocationItem>
          <LocationImage src={map6} alt="location on the map 6" />
          <RedCircle />
          <TextsContainer>
            <LocationName>Life Ride Camden</LocationName>
            <TextContainer>
              <LocationPoint src={marker} alt="marker" />
              <LocationText>80 Cottontail Ln, Somerset, NJ 08873</LocationText>
            </TextContainer>
          </TextsContainer>
        </LocationItem>
      </CardsContainer>
    </LocationsContainer>
  );
};

export default LifeHomeCareLocation;
