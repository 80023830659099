import React, { useState } from "react";
import axios from "axios";
import { API_URI } from "../../config";
import { InputForm } from "./input/index";

import BackImageAVIF from "./assets/callback.avif";
import BackImage from "./assets/callback.png";
import ImageOffice from "./assets/sec.png";

import { MainContainer } from "../../styles/globals/styles";

import {
  ContactContainer,
  TextContainer,
  ImageContainer,
  Picture,
  Title,
  Line,
  Text,
  Button,
  Form,
  TextContent,
  BottomSection,
  FormMessage,
  OfficeImage,
} from "./styles";

const OfficeStaff = () => {
  return (
    <ContactContainer id="callBack">
      <TextContainer>
        <TextContent>
          <Title>OUR BACK OFFICE STAFF</Title>
          <Line />
          <Text>
            The team working at the office is at your disposal to make your job
            easier and fix any problem that might come up in your day-to-day.
          </Text>
        </TextContent>
      </TextContainer>
      <ImageContainer>
        <OfficeImage src={ImageOffice} alt="emt office staff" />
      </ImageContainer>
    </ContactContainer>
  );
};

export default OfficeStaff;
