import styled from "styled-components";

import { COLORS } from "../../styles/globals/colors";
import { TABLET_992, TABLET_800, MOBILE_460 } from "../../styles/globals/sizes";

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1500px;
  width:100%;
  margin: auto;
  
  @media (max-width: ${TABLET_800}px) {
    flex-direction: column-reverse;
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  background: transparent linear-gradient(90deg, ${COLORS.red} 0%, ${COLORS.pinkDots} 100%) 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 70px 0;

  @media (max-width: ${TABLET_800}px) {
    width: 100%;
  }
  @media (max-width: ${MOBILE_460}px) {
    padding: 30px 0 50px;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;

  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    align-items: center;
  }
  @media (max-width: ${MOBILE_460}px) {
    padding: 30px 0 50px;
    flex-direction: column;
  }
`;

export const TextContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 80px;

  @media (max-width: ${TABLET_800}px) {
    width: 90%;
  }
  @media (max-width: ${MOBILE_460}px) {
    padding-left: 0;
    display: none;
  }
`;

export const TextContentMobile = styled.div`
  display: none;
  width: 100%;
  flex-direction: column;
  padding-left: 80px;

  @media (max-width: ${TABLET_800}px) {
    width: 90%;
  }
  @media (max-width: ${MOBILE_460}px) {
    padding-left: 0;
    display: flex;
  }
`;

export const TextContentSecond = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 80px;
  margin-top: 87px;

  @media (max-width: ${TABLET_800}px) {
    width: 90%;
    margin-top: 27px;
  }
  @media (max-width: ${MOBILE_460}px) {
    display: none;
    padding-left: 0;
  }
`;

export const TextContentSecondMobile = styled.div`
  display: none;
  width: 100%;
  flex-direction: column;
  padding-left: 80px;
  margin-top: 87px;

  @media (max-width: ${TABLET_800}px) {
    width: 90%;
    margin-top: 27px;
  }
  @media (max-width: ${MOBILE_460}px) {
    display: flex;
    padding-left: 0;
  }
`;

export const ImageContainer = styled.div`
  width: 50%;
  max-height: 580px;
  @media (max-width: ${TABLET_992}px) {
    max-height: 100%;
  }
  @media (max-width: ${TABLET_800}px) {
    width: 100%;
    height: 488px;
  }
  @media (max-width: ${MOBILE_460}px) {
    height: 320px;
  }
`;

export const Picture = styled.picture`
  & > img, 
  & > source {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    @media (max-width: ${TABLET_800}px) {
      object-position: top left;
    }
  }
`;

export const Title = styled.div`
  font: normal normal normal 30px/28px Roboto;
  color: ${COLORS.white};
  margin-bottom: 30px;
  margin-top: 30px;

  @media (max-width: ${MOBILE_460}px) {
    font: normal normal normal 28px/40px Roboto;
  }
`;

export const Line = styled.div`
  width: 30%;
  border-bottom: 4px solid ${COLORS.white};
  margin: 20px 0;
`;

export const Text = styled.div`
  font: normal normal medium 20px/28px Roboto;
  color: ${COLORS.white};
  margin-bottom: 27px;
`;

export const Label = styled.label`
  font: normal 20px/28px Roboto;
  margin-top: 20px;
  color: ${COLORS.white};
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Input = styled.input`
  background: ${COLORS.white}5f 0% 0% no-repeat padding-box;
  border: 1px solid ${COLORS.white};
  height: 48px;
  margin-top: 22px;

  ::placeholder {
    color: ${COLORS.white};
  }
`;

export const Button = styled.button`
  background: ${COLORS.white} 0% 0% no-repeat padding-box;
  border-radius: 24px;
  color: ${COLORS.redTitle};
  width: fit-content;
  padding: 12px 80px;
  border: none;
  font-size: 18px;
  white-space: nowrap;
`;

export const BottomSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 43px;

  @media (max-width: 1030px) {
    flex-direction: column;
    margin: 40px auto 0;
  }
`;

export const AdittionalPadding = styled.div`
width: 100%;
height: 100%;
padding-top: 90px;
// @media (max-width: ${MOBILE_460}px) {
//   width: 95%;
// }
`;

export const Select = styled.select`
  background: #ffffff5f 0% 0% no-repeat padding-box;
  border: 1px solid ${COLORS.white};
  color: ${COLORS.white};
  height: 48px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 80%;
  padding-left: 23px;
  ::placeholder {
    color: ${COLORS.white};
    font-size: 18px;
  }
 
  @media (max-width: ${MOBILE_460}px) {
    width: 100%;
  }
`;

export const MenuItem = styled.option`
background: #c7c0c3 0% 0% no-repeat padding-box;
color: ${COLORS.white};
`;

export const RadioContainer = styled.div`
width: 80%;
margin-top: 20px;
display: flex;

color: ${COLORS.white};
`;

export const RadioInput = styled.input`
  display: flex;
  margin-right: 15px;

  width: 17px;
  height: 17px;
  border: 2px solid #9a9a9a;
  border-radius: 50%;
  cursor: pointer;
  background: ${COLORS.red};
`;

export const LabelOption = styled.label`
display: flex;
margin-right: 100px;

color: ${COLORS.white};
`;

export const FormMessage = styled.div`
  padding-left: 80px;
  color: ${COLORS.white};
  margin: auto;
  margin-left: 20px;
`;

export const CheckboxWrapper = styled.label`
  width: 100%;
  margin-top: 20px;
  padding-left: 80px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
`;

export const CheckboxWrapperLong = styled.label`
  width: 100%;
  margin-top: 5px;
  padding-left: 80px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
`;
export const CheckboxInput = styled.input`
  margin-right: 5px;
`;

export const CheckboxInputLong = styled.input`
  margin-right: 5px;
`;

export const CheckboxText = styled.span`
  font: normal 16px/28px Roboto;
  color: ${COLORS.white};
  margin-right: 10px;

  @media (max-width: ${MOBILE_460}px) {
    font: normal normal normal 14px/28px Roboto;
  }
`;

export const ModalDescription = styled.p`
  font: normal normal normal 16px/24px Roboto;
  letter-spacing: 0px;
`