import styled from "styled-components";

import { COLORS } from "../../styles/globals/colors";

import {
  DESKTOP_1200,
  TABLET_992,
  TABLET_800,
  TABLET_740,
  MOBILE_460,
  DESKTOP_1024,
} from "../../styles/globals/sizes";

export const CredentialsBackground = styled.div`
  display: none;

  @media (max-width: ${MOBILE_460}px) {
    max-width: 1500px;
    //height: 439px;
    margin: 0 auto;
    background: ${COLORS.gray95};
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding-left: 20px;
  }
`;


export const CredentialsContainer = styled.div`
  width: 100%;
  max-width: 460px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

export const CredentialInfo = styled.div`
  color: ${COLORS.redTitle};
  text-align: left;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: left;

  @media (max-width: ${MOBILE_460}px) {
    display: block;
    text-align: left;
    width: 100%;
    max-width: 100%;
  }
`;

export const BenefitsInfo = styled.div`
  color: ${COLORS.redTitle};
  text-align: left;
  width: 40%;
  margin-top: 79px;
  //padding-left: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${MOBILE_460}px) {
    display: block;
    text-align: left;
    width: 100%;
    max-width: 100%;
  }
`;

export const CredentialText = styled.p`
  margin: 15px 0;
  font-size: 20px;
  line-height: 30px;
  color: ${COLORS.white};
  text-align: left;
  
  & span {
    font-weight: 500;
  }

  @media (max-width: ${DESKTOP_1200}px) {
    font-size: 18px;
    line-height: 26px;
  }
  @media (max-width: ${TABLET_992}px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media (max-width: ${TABLET_800}px) {
    margin: 0;
    width: 640px;
    font-size: 18px;
    line-height: 26px;
  }
  @media (max-width: ${TABLET_740}px) {
    width: auto;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const CredentialPicture = styled.picture`
  width: fit-content;
  justify-self: center;
  align-self: center;
  filter: grayscale(1) brightness(10);

  & > img, 
  & > source {
    height: 100%;

    @media (max-width: ${DESKTOP_1200}px) {
      height: 150px;
    }
    @media (max-width: ${TABLET_992}px) {
      height: 130px;
    }
    @media (max-width: ${TABLET_740}px) {
      height: 125px;
    }
  }
`;

export const Title = styled.h2`
  color: ${COLORS.redTitle};
  margin-bottom: 15px;
  font: 400 30px/39px Roboto;
  text-align: left;
  margin-top: 50px;
`;

export const Line = styled.div`
  width: 149px;
  height: 4px;
  background: ${COLORS.redTitle} 0% 0% no-repeat padding-box;
  opacity: 1;
`;

export const InfoList = styled.ul`
  list-style: inside;

`;

export const Item = styled.li`
  width: 100%;
  margin: 20px 0px;
  font: normal normal 18px/24px Roboto;
  color: #464748;
  font-weight: 600;
  padding-left: 1.28571429em;
  text-indent: -1.28571429em;
`;

export const ItemRight = styled.li`
  width: 100%;
  margin: 25px 0px;
  font: normal normal 18px/24px Roboto;
  color: #464748;
  font-weight: 600;
  padding-left: 1.28571429em;
  text-indent: -1.28571429em;
`;

export const ArrowLeft = styled.img`
  width: 30px;
  height: 30px;
  position: absolute;
  left: 3%;
  top: 45%;
  transform: rotate(180deg);
  z-index: 20;
`;

export const ArrowRight = styled.img`
  width: 14px;
  height: 24px;
  position: absolute;
  right: 3%;
  top: 10%;
  z-index: 20;
`;

export const Picture = styled.img`
  object-fit: cover;
  bottom: -90px;
  width: 100%;
  position: absolute;
`;

export const AmbulancePicture = styled.img`
  object-fit: cover;
  top: 170px;
  width: 620px;
  clip-path: inset(0 40% 0 0);
  height: 495px;
  position: absolute;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 150px;
  max-width: 578px;
  position: relative;
`;

export const CarrouselContent = styled.div`
  width: 90%;
  margin-top: 35px;
  .swiper-pagination-bullet-active {
    background-color: red !important;
  }
`