import {
  CardParagraph,
  CardTitle,
  HeroButton,
  HeroContainer,
  HeroContainerMobile,
  HeroImage,
  HeroSubTitle,
  HeroTextContainer,
  HeroTitle,
  HeroWrapper,
  OurTeamTitle,
  PlayButton,
  StatisticItem,
  StatisticName,
  StatisticNumber,
  StatisticsContainer,
  TextContainer,
  TextContainerOurTeam,
  TextContent,
  TwoDivsContainer,
  VideoContainer,
} from "../styles/pages/index.jsx";
import { Helmet, HelmetProvider } from "react-helmet-async";
import React, { useEffect } from "react";

import AOS from "aos";
import ContactForm from "../components/contactForm/contactForm";
import Credential from "../components/Credential";
import CredentialMobile from "../components/CredentialMobile";
import GetInTouchSection from "../components/GetInTouchSection";
import Hero from "../images/hero.jpeg";
import Layout from "../components/layout";
import LifeHomeCareLocation from "../components/GoogleMaps";
import { MainContainer } from "../styles/globals/styles";
import OfficeStaff from "../components/officeStaff";
import Play from "../images/play.svg";
import ReactPlayer from "react-player";
import Seoinfo from "../components/seoinfo/seoinfo";
import seoImage from "../../static/seo/seo_image.png";

const Home = () => {
  useEffect(
    () => {
      AOS.init({
        //initialise with other settings
        duration: 2000,
        once: true,
      });
    },
    [],
    AOS
  );

  return (
    <HelmetProvider>
      <Layout>
        <Helmet>
          <link rel="canonical" href="https://emt.life/" />
        </Helmet>
        <Seoinfo
          siteName={"JOIN US AT LIFE RIDE"}
          titlePage={"JOIN US AT LIFE RIDE"}
          description={
            "Discover the incredible advantages and benefits of joining our team! Don't miss out on this amazing opportunity. Join us today!"
          }
          keywords={
            "benefits, EMT, recruitment, competitive pay, flexible schedule, friendly enviroment"
          }
          type={"website"}
          url={"emt.life"}
          imageFacebook={seoImage}
        />
        <HeroWrapper>
          <HeroContainer alt="Woman hugging elder woman">
            <HeroTextContainer hasRotate>
              <HeroTitle>
                JOIN US AT <br />
                LIFE RIDE
              </HeroTitle>
              <HeroSubTitle>
                Get to know us and learn all the advantages and benefits of
                working here!
              </HeroSubTitle>
              <HeroButton href="#callBack">Apply now</HeroButton>
            </HeroTextContainer>
          </HeroContainer>
          <HeroContainerMobile>
            <HeroImage src={Hero} alt="emt staff taking patient to ambulance" />
            <HeroTextContainer>
              <HeroTitle>
                JOIN US AT <br />
                LIFE RIDE
              </HeroTitle>
              <HeroSubTitle>
                Get to know us and learn all the advantages and benefits of
                working here!
              </HeroSubTitle>
              <HeroButton href="#callBack">Apply now</HeroButton>
            </HeroTextContainer>
          </HeroContainerMobile>
        </HeroWrapper>
        <TwoDivsContainer>
          <TextContainer>
            <TextContent>
              <CardTitle>
                WE HAVE MADE A TREMENDOUS EFFORT TO CREATE THE BEST WORK
                ENVIRONMENT FOR OUR STAFF!
              </CardTitle>
              <CardParagraph>
                As an EMT at Life Ride, you become a part of our community and
                family, where we look out for each other and help one another in
                times of need. As a company, we will always strive to support
                your personal and career growth.
              </CardParagraph>
            </TextContent>
          </TextContainer>
        </TwoDivsContainer>
        <TextContainerOurTeam>
          <OurTeamTitle>OUR TEAM</OurTeamTitle>
        </TextContainerOurTeam>
        <VideoContainer>
          <ReactPlayer
            controls
            url="https://vimeo.com/809130177"
            width="100%"
            height="100%"
            playing
            playIcon={<PlayButton src={Play} />}
            light={true}
          />
        </VideoContainer>

        <Credential />
        <CredentialMobile />

        <ContactForm />
        <TextContainerOurTeam>
          <OurTeamTitle>OUR COMPANY</OurTeamTitle>
        </TextContainerOurTeam>
        <OfficeStaff />
        <MainContainer>
          <StatisticsContainer>
            <StatisticItem>
              <StatisticNumber>400</StatisticNumber>
              <StatisticName>employees</StatisticName>
            </StatisticItem>
            <StatisticItem hasMarginTop>
              <StatisticNumber>150</StatisticNumber>
              <StatisticName>vehicles</StatisticName>
            </StatisticItem>
            <StatisticItem hasMarginTop>
              <StatisticNumber>92%</StatisticNumber>
              <StatisticName>retention</StatisticName>
            </StatisticItem>
          </StatisticsContainer>
        </MainContainer>
        <LifeHomeCareLocation />
        {/* <ServicesCards /> */}
        <GetInTouchSection />
      </Layout>
    </HelmetProvider>
  );
};

export default Home;
